import axios from "axios";

export default {
    async getTickets(id: string) {
        const data = new FormData();
        //data.append("where[]", "tickets_data_assegnazione IS NOT NULL");
        //data.append("where[]", "tickets_status <> 5");
        data.append("orderby", "tickets_assign_date");
        data.append("orderdir", "desc");
        data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = ${id.toString()})`);
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/tickets", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    async getTicketData(id: string) {
        const data = new FormData();
        data.append("where[tickets_id]", id.toString());
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/tickets", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    async getTicketDetail(id: string, userID: string) {
        const data = new FormData();
        data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = ${userID.toString()})`);
        data.append("where[tickets_id]", id.toString());
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/tickets", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    /**
     *
     * @param projectID commessa ID
     * @param userID user ID
     * @returns ticket assigned to me for a specified commessa
     */
    async getTicketCommessa(projectID: string, userID: string) {
        const data = new FormData();
        data.append("where[]", "tickets_status <> 5");
        data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = ${userID.toString()})`);
        data.append("where[tickets_project_id]", projectID.toString());

        const response = await axios.post("rest/v1/search/tickets", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data);
        return response.data.data;
    },

    /**
     *
     * @param intervento array with intervento data
     * @returns intervento just created
     *
     * Aggiorna un intervento con la preferenza dell'invio mail al cliente, effettuato dal dettaglio intervento
     */
    async changeTicketStatus(idTicket) {
        const data = new FormData();
        data.append("tickets_status", "5");
        const response = await axios.post(`rest/v1/edit/tickets/${idTicket}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     *
     * Get all tickets without technician and status = APERTO
     */
    async getFreeTickets() {
        const data = new FormData();
        data.append("where[]", "tickets_status = 1");
        data.append("orderby", "tickets_assign_date");
        data.append("orderdir", "desc");
        data.append("where[]", "tickets_id NOT IN (SELECT tickets_id FROM tickets_tecnici)");
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/tickets", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    /**
     *
     * Get informatin for a free ticket
     */
    async getFreeTicketDetail(id: string) {
        const data = new FormData();
        data.append("where[]", "tickets_id NOT IN (SELECT tickets_id FROM tickets_tecnici)");
        data.append("where[tickets_id]", id.toString());
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/tickets", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    /**
     *
     * Auto assign ticket to the logged user for today
     */
    async assignTicket(idTicket: string, tecnici, dataAssegnazione) {
        const data = new FormData();

        data.append("tickets_assign_date", dataAssegnazione);
        tecnici.forEach((tecnico) => {
            data.append("tickets_tecnici[]", tecnico);
        });

        const response = await axios.post(`rest/v1/edit/tickets/${idTicket}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    /**************************************************************
     * New field - 01/09/2022
     */
    /**
     * @param projectID commessa ID
     * @param userID user ID
     * @returns ticket assigned to me for a specified commessa
     */
    async getProjectTickets(projectID: string) {
        const data = new FormData();
        //data.append("where[]", "tickets_status <> 5");
        //data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = ${userID.toString()})`);
        data.append("where[tickets_project_id]", projectID);
        data.append("orderby", "tickets_creation_date");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/tickets", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data);
        return response.data;
    },

    async getMessages(id: string) {
        const data = new FormData();
        data.append("where[tickets_messages_ticket]", id.toString());
        data.append("orderby", "tickets_messages_creation_Date");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/tickets_messages", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data.data);
        return response.data.data;
    },
};
